const baseColors = {
  white: "#f0f0f0",
  black: "#1C1C1B",
  transparent: "transparent",

  grey20: "#F9F6F9",
  grey40: "#EEE6ED",
  grey90: "#1C1C1B",

  teal30: "#8298b0",
  teal40: "#afc5d9",

  greyBlue30: "#8ea2b7",
  green50: "#689778",
  sand50: "#F1E8D2",
  grey50: "#CCCCCC",
};

const themeColors = {
  ...baseColors,
  primary: baseColors.green50,
  primaryActive: baseColors.green50,
  primaryDisabled: baseColors.grey90,

  secondary: baseColors.sand50,
  secondaryActive: baseColors.sand50,
  secondaryDisabled: baseColors.sand50,

  bgColor: baseColors.black,
  secondaryBgColor: baseColors.grey90,

  fgColor: baseColors.grey50,
  secondaryFgColor: baseColors.grey50,

  accent: baseColors.green50,
};

const colors = {
  ...themeColors,
};

export default colors;
