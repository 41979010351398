import React from "react";
import styled from "styled-components";

import {
  StyledNavItem,
  HamburgerIcon,
  NavWrapper,
} from "./styled";
import { Props } from "./types";
import { LogoTypeImage } from "@components/Image";

interface LinksContainerProps {
  isOpen: boolean;
}

const LinksContainer = styled.div`
  display: ${(props: LinksContainerProps) =>
    props.isOpen ? "flex" : "none"};
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.secondaryBgColor};
  padding: 30px 0;

  a {
    padding: 8px 0;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    display: flex;
    position: relative;
    top: 0;
    width: auto;
    flex-direction: row;
    background: none;
    padding: 0;

    a {
      padding-left: 44px;
    }
  }

`

const NavBar = ({ className, ...props }: Props) => {
  const [hamburgerOpened, setHamburger] = React.useState(false);

  const toggleHamburger = () => setHamburger(!hamburgerOpened);

  const closeHamburger = () => setHamburger(false);

  return (
    <NavWrapper className={className}>
      <LogoTypeImage src="./images/logotype.svg" />
      <HamburgerIcon onClick={toggleHamburger} />
      <LinksContainer isOpen={hamburgerOpened}>
        <StyledNavItem url="#home" internal onClick={closeHamburger}>
          Home
        </StyledNavItem>
        <StyledNavItem url="#about" internal onClick={closeHamburger}>
          About
        </StyledNavItem>
        <StyledNavItem url="#collectr" internal onClick={closeHamburger}>
          Collectr
        </StyledNavItem>
        <StyledNavItem url="#family" internal onClick={closeHamburger}>
          Kusari Family
        </StyledNavItem>
      </LinksContainer>
    </NavWrapper>
  );
};

export default NavBar;
