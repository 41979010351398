import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:wght@400;500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap');

  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-size: ${({ theme }) => theme.fontSizes.small};
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 400;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    padding-top: ${({ theme }) => theme.sizes.navbarHeight};
  }
`;

export default GlobalStyle;
