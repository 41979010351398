import styled from "styled-components";

import { LogoTypeImage } from "@src/components/Image";
import { SOCIAL_ICONS } from "@src/components/Cards";
import { ReactElement } from "react";

interface SocialType {
  link: string;
  image: ReactElement;
}

const socials: SocialType[] = [
  {
    link: "https://twitter.com/bykusari",
    image: SOCIAL_ICONS.TWITTER,
  },
  {
    link: "https://www.instagram.com/bykusari",
    image: SOCIAL_ICONS.INSTAGRAM,
  },
  {
    link: "https://discord.gg/cKWpT7HGam",
    image: SOCIAL_ICONS.DISCORD,
  },
  {
    link: "https://mirror.xyz/uwucrew.eth",
    image: SOCIAL_ICONS.MIRROR,
  },

];

const Container = styled.div`
  background: ${({ theme }) => theme.colors.green50};
  width: 100%;
  padding: 60px 80px;
  display: flex;
  justify-content: space-between;
`

const LogoContainer = styled.div`
  display: flex;
  margin: 0 auto;
`;

const SocialsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

const SocialLink = styled.a`
  cursor: pointer;  
  margin-left: 18px;

  svg {
    height: 22px;
    color: white;
  }
`

const Footer = () => {
  return (
    <Container>
      <div />
      <LogoContainer>
        <LogoTypeImage src="./images/whitelogotype.svg" />
      <SocialsContainer>
        {socials.map((social) => (
          <SocialLink href={social.link} target="_blank" rel="noreferrer">
            {social.image}
          </SocialLink>
        ))}
      </SocialsContainer>
      </LogoContainer>
    </Container>
  );
};

export default Footer;
