import React from 'react'

import {
  ColorType,
  SeparatorType,
  Container,
  ContainerSeparator,
} from "@components/Layout";
import { Title, Paragraph } from "@components/Text";
import styled from "styled-components";

const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  text-align: center;
  
  > h1 {
    margin-bottom: 1rem;
  }

  > p {
    max-width: 800px;
    margin: 0 auto;
  }

  > div { // For the EqualHStack
    margin-top: 2rem;
    text-align: left;
  }
`

const About = () => {
  return (
    <>
      <Container colorType={ColorType.SECONDARY} id="about">
        <AboutSection>
          <Title>About</Title>
          <Paragraph>
            Kusari is determined to build a positive future for onchain art by creating through collaboration with the global artist community.
            <br/><br/>By championing crypto-native principles through our work, we inspire new creators, drive demand for onchain art, and make a positive impact on the global art community.
          </Paragraph>
        </AboutSection>
        <ContainerSeparator separatorType={SeparatorType.PARTIAL} />
      </Container>
    </>
  );
};

export default About;
