import styled from "styled-components";

import {
  Container,
  ContainerSeparator,
  HorizontalStack,
  HeroVerticalStack,
} from "@components/Layout";
import { Headline, DropHead } from "@components/Text";
import { Button } from "@components/Buttons";
import { SOCIAL_ICONS } from "@components/Cards";

const Background = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 90%;
  padding-right: 5%;
`

const ButtonContainer = styled.div`
  margin-top: 20px;
`

const SocialContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: white;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
  }
`

const mainSocials = [
  {
    url: "https://twitter.com/bykusari",
    icon: SOCIAL_ICONS.TWITTER,
  },
  {
    url: "https://discord.gg/cKWpT7HGam",
    icon: SOCIAL_ICONS.DISCORD,
  },
  {
    url: "https://www.instagram.com/bykusari",
    icon: SOCIAL_ICONS.INSTAGRAM,
  },
];

const Home = () => {
  const handleClick = () => {
    window.location.href = "./#about";
  };

  return (
    <Container id="home">
      <Background src="/images/bg.svg" />
      <HorizontalStack>
        <HeroVerticalStack align="start">
          <Headline>Empowering <br/>Creativity</Headline>
          <DropHead>
          Our mission is to inspire future generations of creators to express themselves onchain, by collaborating with the global community of onchain artists.
          </DropHead>
          
          <SocialContainer>
            {mainSocials.map((social) => (
              <SocialIcon 
                key={social.url} 
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {social.icon}
              </SocialIcon>
            ))}
          </SocialContainer>

          <ButtonContainer>
            <Button onClick={handleClick}>Learn more</Button>
          </ButtonContainer>
        </HeroVerticalStack>
      </HorizontalStack>
      <ContainerSeparator />
    </Container>
  );
};

export default Home;
