import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Container, ColorType, ContainerSeparator, SeparatorType, EqualHStack } from "@components/Layout";
import { Title } from "@components/Text";
import { IconLink } from '@src/components/Links';
import { SOCIAL_ICONS } from '@src/components/Cards';
import { Social } from '@src/components/Cards/types';

const images = [
  "https://storage.googleapis.com/collectr-assets/november-2024/november-seekgoliath-original.webp",
  "https://storage.googleapis.com/collectr-assets/october-2024/october-2024-compressed.jpg",
  "https://storage.googleapis.com/collectr-assets/august/original-august-2024-compressed.png",
];

const ImageSection = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  @media (min-width: 1024px) {
    width: 50%;
    margin-bottom: 0;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: min(580px, 60%); 
  perspective: 2000px;
`

const ImageCard = styled(motion.div)`
  position: absolute;
  width: min(250px, 50%);
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
`

const ContentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  
  @media (min-width: 1024px) {
    width: 50%;
    padding-left: 2rem;
  }
`

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  max-width: 500px;
  color: rgba(255, 255, 255, 0.8);
`

const SocialContainer = styled.div`
  display: flex;

  > a {
    margin: 0 7px;
  }
`
const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #689778;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  border-radius: 16px;
  text-decoration: none;
  margin-top: 1rem;
  transition: all 0.2s ease;
  cursor: pointer;
  width: fit-content;
  align-self: flex-start;
  
  &:hover {
    background-color: #4c9a80;
    transform: translateY(-2px);
  }
`

let socials = [
  {
    url: "https://collectr.live/",
    icon: SOCIAL_ICONS.WEBSITE,
  },
  {
    url: "https://twitter.com/collectrs",
    icon: SOCIAL_ICONS.TWITTER,
  },
  {
    url: "https://discord.gg/cKWpT7HGam",
    icon: SOCIAL_ICONS.DISCORD,
  },
  {
    url: "https://www.instagram.com/collectr.live/",
    icon: SOCIAL_ICONS.INSTAGRAM,
  },
];


const CollectrSection = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleMouseEnter = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveIndex(null);
  }, []);

  return (
    <Container colorType={ColorType.SECONDARY} id="collectr">
      <EqualHStack>
        <ImageSection>
          <ImageContainer>
            <AnimatePresence>
              {images.map((src, index) => (
                <ImageCard
                  key={src}
                  style={{
                    left: `${10 + index * 15}%`,
                    top: `${5 + index * 5}%`,
                    zIndex: activeIndex === index ? 3 : images.length - index,
                  }}
                  animate={{
                    rotateZ: activeIndex === index ? 0 : (index - 1) * 5,
                    scale: activeIndex === index ? 1.1 : 1,
                    y: activeIndex === index ? -10 : 0,
                  }}
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Image src={src} alt={`Collectr NFT ${index + 1}`} />
                </ImageCard>
              ))}
            </AnimatePresence>
          </ImageContainer>
        </ImageSection>

        <ContentSection>
          <Title>Collectr</Title>
          <Description>
            Explore our onchain art platform where we collaborate with the global community to publish stunning art,
            available to collect each month.
          </Description>

          <SocialContainer>
              {socials.map((social: Social) => (
                <IconLink key={social.url} href={social.url}>
                  {social.icon}
                </IconLink>
              ))}
            </SocialContainer>

          <Button target="_blank" rel="noopener noreferrer" href="https://collectr.live/">Start collecting</Button>
        </ContentSection>
      </EqualHStack>
      <ContainerSeparator separatorType={SeparatorType.PARTIAL} />
    </Container>
  );
}; 

export default CollectrSection;